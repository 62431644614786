<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 17:07:05
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-31 10:34:54
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\culture\video\index.vue
 * @Description: 人艺视界
 *
-->
<template>
  <div class="fixed-page culture-video-index">
    <Breadcrumb class="breadcrumb-1400" />
    <div class="container">
      <div class="top-bar">
        <div class="left-bar" @click="handleDetail(leftItem.targetId)">
          <c-image v-if="leftItem.id" :src="leftItem.image" />
          <p class="overflow-ellipsis">
            {{ leftItem.name }}
          </p>
        </div>
        <div class="right-bar">
          <div
            v-for="item in rightList"
            :key="item.id"
            class="list-item"
            @click="handleDetail(item.targetId)"
          >
            <div class="left">
              <c-image :src="item.image" />
            </div>
            <div class="right">
              <div class="info">
                <p class="line-name">
                  {{ item.name }}
                </p>
                <p class="line-tag">
                  <span v-for="tag in item.tag" :key="tag" class="tag">{{
                    tag
                  }}</span>
                </p>
                <p class="line-info">
                  <span>{{
                    dayjs(item.publishDate || item.updateTime).format(
                      "YYYY-MM-DD"
                    )
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="filter-com">
          <el-input
            v-model="form.title"
            class="input"
            suffix-icon="el-icon-search"
            @change="handleSearch"
          />
          <div class="btn-list">
            <button
              :class="{ active: form.tag === 'all' }"
              @click="handleChange('all')"
            >
              全部
            </button>
            <button
              v-for="option in videoType"
              :key="option.id"
              :class="{ active: form.tag === option.value }"
              @click="handleChange(option.value)"
            >
              {{ option.value }}
            </button>
          </div>
        </div>
        <div class="video-list">
          <div
            v-for="item in list"
            :key="item.id"
            class="video-item"
            @click="handleDetail(item.id)"
          >
            <c-image :src="item.image" />
            <div class="info">
              <p class="line-name">
                {{ item.title }}
              </p>
              <p class="line-tag">
                <span v-for="tag in item.tag" :key="tag" class="tag">{{
                  tag
                }}</span>
              </p>
              <p class="line-info">
                <span>发布人：{{ item.author }}</span>
                <span style="margin-left: 1.0417vw">{{ item.publishDate }}</span>
              </p>
            </div>
          </div>
        </div>
        <div v-if="hasNextPage" class="more-btn-box">
          <button class="more-btn" @click="handleMore">
            <i v-if="loading" class="el-icon-loading" />
            查看更多
          </button>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getArticlePage, getVideoPage } from "@/service/culture";
export default {
  data() {
    return {
      dayjs,
      form: {
        title: "",
        tag: "all",
        status: 1,
        level2: "VIDEO",
        orderSign: 2,
        orderDirection: 1,
        isShow: 1,
      },
      pager: {
        pageNo: 1,
        pageSize: 8,
      },
      total: 0,
      list: [],
      hasNextPage: true,
      loading: false,
      leftItem: {},
      leftList: [],
      rightList: [],
    };
  },
  created() {
    this.$store.dispatch("getDict", ["video-type"]);
    this.handleSearch();
    this.getLeft();
  },
  computed: {
    videoType() {
      return this.$store.state.dictMap["video-type"] || [];
    },
  },
  methods: {
    async getLeft() {
      const res = await getVideoPage();
      this.leftList = res.data?.videoLeft || [];
      this.rightList = res.data?.videoRight || [];
      if (this.leftList.length) {
        this.leftItem = this.leftList[0];
      }
    },
    async getData() {
      try {
        this.loading = true;
        const params = {
          ...this.form,
        };
        if (params.tag === "all") {
          delete params.tag;
        }
        const res = await getArticlePage({
          ...this.pager,
          condition: params,
        });
        if (res.data) {
          let data = res.data.data || [];
          if (data.length < this.pager.pageSize) {
            this.hasNextPage = false;
          }
          data = data.map((item) => ({
            ...item,
            tag: item.tag ? item.tag.split(",") : [],
          }));
          if (this.pager.pageNo === 1) {
            this.list = data;
          } else {
            this.list = this.list.concat(data);
          }
          this.total = res.data.totalCount || 0;
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.list = [];
        }
      } finally {
        this.loading = false;
      }
    },
    handleChange(tag) {
      this.form.tag = tag;
      this.handleSearch();
    },
    handleSearch() {
      this.hasNextPage = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    handleMore() {
      if (!this.hasNextPage) return;
      this.pager.pageNo++;
      this.getData();
    },
    // 查看详情
    handleDetail(id) {
      window.open(`#/culture/video/detail/${id}`);
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");

.culture-video-index {
  p {
    padding: 0;
    margin: 0;
  }
  .container {
    margin: 0 auto 2.6042vw;
    width: 72.9167vw;
  }
  .top-bar {
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 1.5625vw;
    display: flex;
    .left-bar {
      flex-shrink: 0;
      width: 41.6667vw;
      height: 23.3854vw;
      margin-right: 1.5625vw;
      position: relative;
      .img {
        width: 100%;
        height: 100%;
        display: block;
      }
      p {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6042vw;
        background-color: rgba(0, 0, 0, 0.3);
        color: #ffffff;
        padding: 0 1.5625vw;
        box-sizing: border-box;
        line-height: 2.6042vw;
      }
    }
    .right-bar {
      .list-item {
        display: flex;
        cursor: pointer;
        & + .list-item {
          margin-top: 1.1458vw;
        }
        .left {
          width: 12.5vw;
          height: 7.0313vw;
          margin-right: 1.0417vw;
          .img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .right {
          box-sizing: border-box;
          height: 6.7708vw;
          flex: 1;
          .info {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .line {
              &-name {
                // margin-top: 10px;
                font-size: 1.0417vw;
                font-weight: 800;
                color: #212122;
                .text-overflow(2);
              }
              &-tag {
                margin-top: 0.625vw;
                display: flex;
                flex-wrap: wrap;
                .tag {
                  font-size: 0.625vw;
                  font-weight: 400;
                  color: #212122;
                  background: #f8d39f;
                  border-radius: 0.1042vw;
                  height: 1.0417vw;
                  line-height: 1.0417vw;
                  padding: 0 0.4167vw;
                  margin-right: 0.625vw;
                }
              }
              &-info {
                margin-top: 1.0417vw;
                font-size: 0.625vw;
                font-weight: 400;
                color: #909399;
              }
            }
          }
        }
      }
    }
  }
  .bottom-bar {
    margin-bottom: 2.6042vw;
    .filter-com {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2.0833vw 0 1.5625vw;
      .input {
        width: 14.5833vw;
        height: 1.875vw;
        .el-input__icon {
          color: #212122;
          font-weight: bold;
        }
      }
      .btn-list {
        display: flex;
        align-items: center;
        button {
          width: auto;
          padding: 0 1.0417vw;
          height: 1.875vw;
          background: #ffffff;
          border-radius: 0.2083vw;
          border: 0.0521vw solid #d8dade;
          cursor: pointer;
          & + button {
            margin-left: 0.625vw;
          }
        }
        .active {
          background-color: var(--primary-color);
          color: #ffffff;
        }
      }
    }
    .video-list {
      display: grid;
      grid-gap: 2.0833vw;
      grid-template-columns: repeat(4, 16.6667vw);
      .video-item {
        background-color: #ffffff;
        cursor: pointer;
        .img {
          width: 16.6667vw;
          height: 9.375vw;
        }
        .info {
          height: 8.5417vw;
          width: 16.6667vw;
          padding: 1.0417vw;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .line {
            &-name {
              font-size: 1.0417vw;
              font-weight: 800;
              color: #212122;
              .text-overflow(2);
            }
            &-tag {
              display: flex;
              flex-wrap: wrap;
              .tag {
                font-size: 0.625vw;
                font-weight: 400;
                color: #212122;
                background: #f8d39f;
                border-radius: 0.1042vw;
                height: 1.0417vw;
                line-height: 1.0417vw;
                padding: 0 0.4167vw;
                margin-right: 0.625vw;
              }
            }
            &-info {
              font-size: 0.625vw;
              font-weight: 400;
              color: #909399;
            }
          }
        }
      }
    }
    .more-btn-box {
      text-align: center;
      .more-btn {
        margin: 1.5625vw auto 0;
        width: 21.9792vw;
        height: 2.1875vw;
        background: #ffffff;
        border-radius: 0.2083vw;
        border: 0.0521vw solid #d8dade;
        font-size: 0.7292vw;
        color: #212122;
        cursor: pointer;
      }
    }
  }
}
</style>
